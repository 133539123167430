import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { zodUndefinedIfInvalid } from '@digital-magic/react-common/lib/utils/zod'
import { routes } from '@constants/routes'
import { formatDateShort } from '@utils/date-utils'
import { RequestErrorHandler } from '@api/types'
import { OptionCode, OptionId, OptionListItem, useDeleteOption, useGetOptions } from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useDebounce } from '@hooks/useDebounce'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button, RouterButton } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Form, FormTextField, useFormTyped } from '@controls/Form'
import { Table, TableColumn } from '@controls/Table'
import { OptionsStyled } from './styles'

const OptionSearchValues = z.object({
  code: OptionCode.optional()
})

export const Options: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const snackbar = useSnackbar()

  const onDeleteOptionError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.option.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const deleteOption = useDeleteOption({ onError: onDeleteOptionError })

  const onDeleteOption =
    (optionId: OptionId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteOption.mutate(optionId)
      }
    }

  const onRowClick = (row: OptionListItem): void => {
    navigate(generatePath(routes.Admin.OptionEdit, { optionId: row.id }))
  }

  const form = useFormTyped({
    resolver: OptionSearchValues,
    onSubmit: () => {
      return false
    },
    mode: 'all'
  })

  const code = form.watch('code')
  const debouncedCode = useDebounce(code, 500)
  const validCode = zodUndefinedIfInvalid(debouncedCode, OptionCode)

  const getOptions = useGetOptions(validCode, { onError: defaultErrorHandler })

  const tableColumns: ReadonlyArray<TableColumn<OptionListItem>> = React.useMemo(
    () => [
      {
        key: 'code',
        title: t('pages.admin.option.form.fields.code'),
        sortable: true
      },
      {
        key: 'name',
        title: t('pages.admin.option.form.fields.name'),
        sortable: true,
        render: (row) => row.name.eng
      },
      {
        key: 'multiValue',
        title: t('pages.admin.option.form.fields.multi_value'),
        sortable: false,
        render: (row) => (row.multiValue ? 'true' : 'false')
      },
      {
        key: 'createdAt',
        title: t('pages.admin.option.form.fields.created'),
        sortable: true,
        render: (row) => formatDateShort(row.createdAt)
      },
      {
        key: null,
        title: null,
        render: (row) => (
          <ButtonWithConfirmation
            color="error"
            size="small"
            onConfirmResult={onDeleteOption(row.id)}
            confirmTitle={t('global.consent.delete.title')}
            confirmMessage={t('global.consent.delete.message')}
          >
            <Delete />
          </ButtonWithConfirmation>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <OptionsStyled>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>{t('pages.admin.options.title')}</h1>
        <Form f={form}>
          <FormTextField name={form.names.code} label={t('pages.admin.options.search.by_code.label')} />
        </Form>
      </Box>
      <Table
        data={getOptions.data ?? []}
        columns={tableColumns}
        onRowClick={onRowClick}
        initialSortConfig={{ column: 'code', direction: 'ASC' }}
      />
      <Box display="flex" columnGap={2} mt={2}>
        <RouterButton to={routes.Admin.OptionNew}>{t('global.buttons.add')}</RouterButton>
        <Button onClick={() => getOptions.refetch()}>{t('global.buttons.refresh')}</Button>
      </Box>
    </OptionsStyled>
  )
}
